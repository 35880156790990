import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  NumberInput,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { MusteriKart_ScreenBase } from "./musterikart-base";

import { AksiyonKart_Screen } from "../aksiyonkart/aksiyonkart";
import { CariAdresler_Screen } from "../cariadresler/cariadresler";
import { CariIBankaBilgileri_Screen } from "../cariibankabilgileri/cariibankabilgileri";
import { CariIlgiliKisiler_Screen } from "../cariilgilikisiler/cariilgilikisiler";
import { CariSozlesmeler_Screen } from "../carisozlesmeler/carisozlesmeler";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class MusteriKart_Screen extends MusteriKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              musterikart_889558_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              musterikart_802808_value: this.state.SelectCariById?.at?.(0)?.cariAd ?? undefined,
              musterikart_387331_value: undefined,
              musterikart_941181_value: this.state.SelectCariById?.at?.(0)?.cariAdres ?? undefined,
              musterikart_626253_value: this.state.SelectCariById?.at?.(0)?.ulkeKodu ?? undefined,
              musterikart_692424_value: this.state.SelectCariById?.at?.(0)?.ilKodu ?? undefined,
              musterikart_392410_value: this.state.SelectCariById?.at?.(0)?.ilceKodu ?? undefined,
              musterikart_567110_value: this.state.SelectCariById?.at?.(0)?.postaKodu ?? undefined,
              musterikart_626884_value: this.state.SelectCariById?.at?.(0)?.tel1 ?? undefined,
              musterikart_733652_value: this.state.SelectCariById?.at?.(0)?.tel2 ?? undefined,
              musterikart_125943_value: this.state.SelectCariById?.at?.(0)?.tel3 ?? undefined,
              musterikart_668754_value: this.state.SelectCariById?.at?.(0)?.cariMail ?? undefined,
              musterikart_632170_value: this.state.SelectCariById?.at?.(0)?.web ?? undefined,
              musterikart_266751_value: this.state.SelectCariById?.at?.(0)?.cariVD ?? undefined,
              musterikart_439202_value: this.state.SelectCariById?.at?.(0)?.cariVergiNo ?? undefined,
              musterikart_565265_value: this.state.SelectCariById?.at?.(0)?.cariTCNo ?? undefined,
              musterikart_64361_value: this.state.SelectCariById?.at?.(0)?.cariPasaportNo ?? undefined,
              musterikart_617458_value: "00000000-0000-0000-0000-000000000001",
              musterikart_193018_value: this.state.SelectCariById?.at?.(0)?.mamaSozlesmesiVarmi ?? undefined,
              musterikart_728658_value: this.state.SelectCariById?.at?.(0)?.mamaSozlesmesiYok ?? undefined,
              musterikart_982273_value: this.state.SelectCariById?.at?.(0)?.cariMhKod120 ?? undefined,
              musterikart_83025_value: this.state.SelectCariById?.at?.(0)?.cariMhKod320 ?? undefined,
              musterikart_151962_value: this.state.SelectCariById?.at?.(0)?.cariMhKod600 ?? undefined,
              musterikart_684316_value: undefined,
              musterikart_601328_value: this.state.SelectCariById?.at?.(0)?.dagitimStopaj ?? undefined,
              musterikart_846368_value: this.state.SelectCariById?.at?.(0)?.cariOnemliNot ?? undefined,
              musterikart_269654_value: this.state.SelectCariById?.at?.(0)?.eMail ?? undefined,
              musterikart_669306_value: this.state.SelectCariById?.at?.(0)?.mailDil ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="musterikart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="658116"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="572685"
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                    xxl={11}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="138204"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="83067"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriKartComponent_83067_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="musterikart_889558_value">
                        <Image
                          id="889558"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="55603"
                    xs={9}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                    xxl={9}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="785916"
                      value={ReactSystemFunctions.translate(this.ml, 785916, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="831300"
                    xs={1}
                    sm={1}
                    md={1}
                    lg={1}
                    xl={1}
                    xxl={1}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(68, 68, 68, 1)",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  ></KCol>

                  <KCol
                    id="659324"
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    xxl={3}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-right",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="715839"
                      direction="horizontal"
                      size={16}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="661163"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriKartComponent_661163_onClick();
                        }}
                        showCursorPointer
                        iconName="more"
                        hoverText="Zarf Etiketi Bas"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontSize: "14pt",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Icon
                        id="439745"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriKartComponent_439745_onClick();
                        }}
                        showCursorPointer
                        iconName="aspect_ratio"
                        hoverText="Sticker Bas"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            fontSize: "14pt",
                            color: "rgba(172, 38, 42, 1)"
                          } as any
                        }
                      ></Icon>

                      <Icon
                        id="996082"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MusteriKartComponent_996082_onClick();
                        }}
                        showCursorPointer
                        iconName="close"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></Icon>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="musterikart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="595200"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 48,
                      paddingBottom: 48,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="967480"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="879496"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 16,
                          width: "1200px",
                          alignItems: "flex-start",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="376827"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderRightWidth: 1,
                            borderColor: "rgba(155, 155, 155, 1)",
                            borderStyle: "dotted",
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="329794"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="405389"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="70279"
                              value={ReactSystemFunctions.translate(this.ml, 70279, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Form.Item className="kFormItem" name="musterikart_802808_value">
                              <TextInput
                                id="802808"
                                kuikaRef={this.musterikart_802808_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  802808,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderBottomWidth: 1,
                                    borderColor: "rgba(155, 155, 155, 1)",
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(47, 47, 47, 1)"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="658573"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="745914"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="855595"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 16,
                                  paddingBottom: 0,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="115888"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="238965"
                                  value={ReactSystemFunctions.translate(this.ml, 238965, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Icon
                                  id="782963"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_782963_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>

                              <KCol
                                id="978718"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="949495"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_949495_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp949495Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="558661"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="89186"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriKartComponent_89186_onClick();
                                      }}
                                      showCursorPointer
                                      value={ReactSystemFunctions.translate(this.ml, 89186, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="126315"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="959043"
                                      iconName="navigate_next"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="973775"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_973775_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp973775Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="460470"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="22958"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriKartComponent_22958_onClick();
                                      }}
                                      showCursorPointer
                                      value={ReactSystemFunctions.translate(this.ml, 22958, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="225519"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="242702"
                                      iconName="navigate_next"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="58005"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 16,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="15381"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "flex-start",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="435813"
                                  visibility={this.state.isComp435813Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(252, 250, 231, 1)",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="333802"
                                    xs={18}
                                    sm={18}
                                    md={18}
                                    lg={18}
                                    xl={18}
                                    xxl={18}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="musterikart_387331_value">
                                      <KSelectBox
                                        id="387331"
                                        kuikaRef={this.musterikart_387331_value_kuikaSelectBoxRef}
                                        options={this.state.sbMusteriTuruList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          387331,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        autoClearSearchValue={true}
                                        showSearch={true}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(252, 250, 231, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="285308"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="121663"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriKartComponent_121663_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_task"
                                      hoverText="Ekle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          color: "rgba(65, 117, 5, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>

                                  <KCol
                                    id="845684"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 12,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="69679"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriKartComponent_69679_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="highlight_off"
                                      hoverText="Vazgeç"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-right",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="578227"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="44486"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTable
                                      id="989333"
                                      kuikaRef={this.musterikart_989333_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.getListMusteriTuruByCariID}
                                      size="middle"
                                      bordered={true}
                                      showHeader={false}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        989333,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      insertRowActive={false}
                                      searchable={false}
                                      filtering={false}
                                      transformedOnMobileResolution={false}
                                      hideHeaderOnMobileResolution={false}
                                      fixedHeader={false}
                                      editable={false}
                                      tableWidthMode="fit"
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      columnChooser={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      editableAlwaysActive={false}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="414954"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="64655"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="970722"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            970722,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          footerFontSize={14}
                                          footerFontColor="rgb(68, 68, 68)"
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderRightWidth: 1,
                                              borderBottomWidth: 1,
                                              borderLeftWidth: 1,
                                              borderColor: "rgba(218, 218, 218, 1)",
                                              borderStyle: "solid",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <KRow
                                            id="94307"
                                            align="top"
                                            justify="start"
                                            horizontalGutter={0}
                                            verticalGutter={0}
                                            style={
                                              {
                                                borderBottomWidth: 1,
                                                borderColor: "rgba(155, 155, 155, 1)",
                                                borderStyle: "dotted",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                height: "32px",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <KCol
                                              id="462148"
                                              xs={22}
                                              sm={22}
                                              md={22}
                                              lg={22}
                                              xl={22}
                                              xxl={22}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingLeft: 6,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="483786"
                                                value="[datafield:mTuruTanim]"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "12px",
                                                    color: "rgba(47, 47, 47, 1)",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              ></Label>
                                            </KCol>

                                            <KCol
                                              id="646938"
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={2}
                                              xl={2}
                                              xxl={2}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-center",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <Icon
                                                id="989982"
                                                onClick={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MusteriKartComponent_989982_onClick();
                                                }}
                                                showCursorPointer
                                                iconName="remove_circle_outline"
                                                hoverText="Sil"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-center",
                                                    color: "rgba(172, 38, 42, 1)"
                                                  } as any
                                                }
                                              ></Icon>
                                            </KCol>
                                          </KRow>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="429694"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="301898"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="597603"
                                  value={ReactSystemFunctions.translate(this.ml, 597603, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_941181_value">
                                  <TextInput
                                    id="941181"
                                    kuikaRef={this.musterikart_941181_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      941181,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="589567"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="110899"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="908497"
                                  value={ReactSystemFunctions.translate(this.ml, 908497, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_626253_value">
                                  <KSelectBox
                                    id="626253"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MusteriKartComponent_626253_onChange();
                                    }}
                                    kuikaRef={this.musterikart_626253_value_kuikaSelectBoxRef}
                                    options={this.state.sbUlkeList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      626253,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="mhKodu"
                                    datatextfield="ulkeTanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="588708"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="250539"
                                  value={ReactSystemFunctions.translate(this.ml, 250539, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_692424_value">
                                  <KSelectBox
                                    id="692424"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MusteriKartComponent_692424_onChange();
                                    }}
                                    kuikaRef={this.musterikart_692424_value_kuikaSelectBoxRef}
                                    options={this.state.sbIlList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      692424,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="mhKodu"
                                    datatextfield="ilTanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="983009"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="287018"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="463982"
                                  value={ReactSystemFunctions.translate(this.ml, 463982, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_392410_value">
                                  <KSelectBox
                                    id="392410"
                                    kuikaRef={this.musterikart_392410_value_kuikaSelectBoxRef}
                                    options={this.state.sbIlceList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      392410,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="mhKodu"
                                    datatextfield="ilceTanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="122722"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="484939"
                                  value={ReactSystemFunctions.translate(this.ml, 484939, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_567110_value">
                                  <TextInput
                                    id="567110"
                                    kuikaRef={this.musterikart_567110_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      567110,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="49170"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="124596"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="876194"
                                  value={ReactSystemFunctions.translate(this.ml, 876194, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_626884_value">
                                  <TextInput
                                    id="626884"
                                    kuikaRef={this.musterikart_626884_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      626884,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="615072"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="380113"
                                  value={ReactSystemFunctions.translate(this.ml, 380113, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_733652_value">
                                  <TextInput
                                    id="733652"
                                    kuikaRef={this.musterikart_733652_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      733652,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="707568"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="145098"
                                  value={ReactSystemFunctions.translate(this.ml, 145098, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_125943_value">
                                  <TextInput
                                    id="125943"
                                    kuikaRef={this.musterikart_125943_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      125943,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="467023"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="581526"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 4,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="336009"
                                  value={ReactSystemFunctions.translate(this.ml, 336009, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_668754_value">
                                  <TextInput
                                    id="668754"
                                    kuikaRef={this.musterikart_668754_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      668754,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="600249"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="550638"
                                  value={ReactSystemFunctions.translate(this.ml, 550638, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <KRow
                                  id="38164"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="639078"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="musterikart_632170_value">
                                      <TextInput
                                        id="632170"
                                        kuikaRef={this.musterikart_632170_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          632170,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="824160"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="883219"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.MusteriKartComponent_883219_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="preview"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="991244"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="68148"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="157781"
                                  value={ReactSystemFunctions.translate(this.ml, 157781, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_266751_value">
                                  <TextInput
                                    id="266751"
                                    kuikaRef={this.musterikart_266751_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      266751,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="145907"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="798245"
                                  value={ReactSystemFunctions.translate(this.ml, 798245, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_439202_value">
                                  <TextInput
                                    id="439202"
                                    kuikaRef={this.musterikart_439202_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      439202,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="150830"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="823391"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="286479"
                                  value={ReactSystemFunctions.translate(this.ml, 286479, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_565265_value">
                                  <TextInput
                                    id="565265"
                                    kuikaRef={this.musterikart_565265_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      565265,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="326852"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="19185"
                                  value={ReactSystemFunctions.translate(this.ml, 19185, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_64361_value">
                                  <TextInput
                                    id="64361"
                                    kuikaRef={this.musterikart_64361_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      64361,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="829244"
                              visibility={this.state.isComp829244Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="313306"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="941444"
                                  value={ReactSystemFunctions.translate(this.ml, 941444, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_617458_value">
                                  <KSelectBox
                                    id="617458"
                                    kuikaRef={this.musterikart_617458_value_kuikaSelectBoxRef}
                                    options={this.state.getListFiyatListeAd}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      617458,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="tanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="973943"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="873836"
                                xs={15}
                                sm={15}
                                md={15}
                                lg={15}
                                xl={15}
                                xxl={15}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="916126"
                                  value={ReactSystemFunctions.translate(this.ml, 916126, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(47, 47, 47, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="1243"
                                xs={9}
                                sm={9}
                                md={9}
                                lg={9}
                                xl={9}
                                xxl={9}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="362536"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="466713"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="musterikart_193018_value">
                                      <Checkbox
                                        id="193018"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MusteriKartComponent_193018_onChange();
                                        }}
                                        children={ReactSystemFunctions.translate(
                                          this.ml,
                                          193018,
                                          "children",
                                          this.defaultML
                                        )}
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Checkbox>
                                    </Form.Item>

                                    <Label
                                      id="647515"
                                      value={ReactSystemFunctions.translate(this.ml, 647515, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingLeft: 8,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="630096"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="musterikart_728658_value">
                                      <Checkbox
                                        id="728658"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MusteriKartComponent_728658_onChange();
                                        }}
                                        children={ReactSystemFunctions.translate(
                                          this.ml,
                                          728658,
                                          "children",
                                          this.defaultML
                                        )}
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Checkbox>
                                    </Form.Item>

                                    <Label
                                      id="392021"
                                      value={ReactSystemFunctions.translate(this.ml, 392021, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingLeft: 8,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(47, 47, 47, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="648844"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="256093"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="60145"
                                  value={ReactSystemFunctions.translate(this.ml, 60145, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_982273_value">
                                  <TextInput
                                    id="982273"
                                    kuikaRef={this.musterikart_982273_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      982273,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="202729"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="575338"
                                  value={ReactSystemFunctions.translate(this.ml, 575338, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_83025_value">
                                  <TextInput
                                    id="83025"
                                    kuikaRef={this.musterikart_83025_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      83025,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="654757"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="614102"
                                  value={ReactSystemFunctions.translate(this.ml, 614102, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_151962_value">
                                  <TextInput
                                    id="151962"
                                    kuikaRef={this.musterikart_151962_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      151962,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="282259"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="931161"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="360475"
                                  visibility={this.state.isComp360475Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 360475, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_684316_value">
                                  <NumberInput
                                    id="684316"
                                    visibility={this.state.isComp684316Visible}
                                    decimalSeparator="."
                                    decimalScale={2}
                                    disabled={false}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      684316,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    fixedDecimalScale={false}
                                    allowNegative={true}
                                    allowEmptyFormatting={false}
                                    isThousandSeperatorOn={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></NumberInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="252201"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="754623"
                                  value={ReactSystemFunctions.translate(this.ml, 754623, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_601328_value">
                                  <NumberInput
                                    id="601328"
                                    decimalSeparator="."
                                    decimalScale={2}
                                    disabled={false}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      601328,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    fixedDecimalScale={false}
                                    allowNegative={true}
                                    allowEmptyFormatting={false}
                                    isThousandSeperatorOn={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></NumberInput>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="162363"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 48,
                              paddingBottom: 48,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="650956"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 16,
                                paddingLeft: 16,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="861691"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriKartComponent_861691_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 861691, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>

                          <KCol
                            id="589969"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Button
                              id="356553"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.MusteriKartComponent_356553_onClick();
                              }}
                              showCursorPointer
                              label={ReactSystemFunctions.translate(this.ml, 356553, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              iconPosition="right"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(172, 38, 42, 1)",
                                  borderStyle: "solid",
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>
                      </KCol>

                      <KCol
                        id="943517"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingRight: 16,
                            paddingLeft: 16,
                            alignItems: "flex-start",
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="972252"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="159706"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="600473"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="655165"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="516287"
                                  value={ReactSystemFunctions.translate(this.ml, 516287, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="259695"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="44312"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="musterikart_846368_value">
                                  <TextArea
                                    id="846368"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      846368,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoSize={false}
                                    showCount={false}
                                    formatter="None"
                                    readOnly={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextArea>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="802825"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="294870"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="635010"
                                  value={ReactSystemFunctions.translate(this.ml, 635010, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="568389"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="429025"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_429025_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Güncel Gelişmeler ve Notlar"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "18pt",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="102734"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="842116"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="130742"
                                  kuikaRef={this.musterikart_130742_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListBFormuAksiyon}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    130742,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="865256"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(208, 2, 27, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="395787"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="124150"
                                      title={ReactSystemFunctions.translate(this.ml, 124150, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="588709"
                                        value="[datafield:aksiyonTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="790683"
                                      title={ReactSystemFunctions.translate(this.ml, 790683, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="635026"
                                        value="[datafield:nick]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="156255"
                                      title={ReactSystemFunctions.translate(this.ml, 156255, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="534677"
                                        value="[datafield:aksiyonTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="178001"
                                      title={ReactSystemFunctions.translate(this.ml, 178001, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="58767"
                                        value="[datafield:aciklama]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="834451"
                                      title={ReactSystemFunctions.translate(this.ml, 834451, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="267785" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="232864"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_232864_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="8393"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_8393_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="701249"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="692570"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="450613"
                                  value={ReactSystemFunctions.translate(this.ml, 450613, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="971383"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="758058"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_758058_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Ek İsim ve İletişim Bilgileri"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "18pt",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="877998"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="530484"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="586219"
                                  kuikaRef={this.musterikart_586219_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListCariIlgiliKisiler}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    586219,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(255, 255, 255, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="122815"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="870110"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="839802"
                                      title={ReactSystemFunctions.translate(this.ml, 839802, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="432076"
                                        value="[datafield:adSoyad]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="574033"
                                      title={ReactSystemFunctions.translate(this.ml, 574033, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="56614"
                                        value="[datafield:gorevi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="330558"
                                      title={ReactSystemFunctions.translate(this.ml, 330558, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="164471"
                                        value="[datafield:telefon]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="754739"
                                      title={ReactSystemFunctions.translate(this.ml, 754739, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="128319"
                                        value="[datafield:eMail]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="883962"
                                      title={ReactSystemFunctions.translate(this.ml, 883962, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingLeft: 2,
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer
                                        id="998123"
                                        direction="horizontal"
                                        size={8}
                                        style={
                                          {
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            alignItems: "center"
                                          } as any
                                        }
                                      >
                                        <VerticalStack
                                          id="714282"
                                          direction="vertical"
                                          size={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="717093"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MusteriKartComponent_717093_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="more"
                                            hoverText="Zarf Etiketi Bas"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontSize: "14pt",
                                                color: "rgba(172, 38, 42, 1)"
                                              } as any
                                            }
                                          ></Icon>

                                          <Icon
                                            id="47199"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MusteriKartComponent_47199_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="aspect_ratio"
                                            hoverText="Sticker Bas"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontSize: "14pt",
                                                color: "rgba(172, 38, 42, 1)"
                                              } as any
                                            }
                                          ></Icon>
                                        </VerticalStack>

                                        <Icon
                                          id="123813"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_123813_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="574746"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_574746_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="563965"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="34189"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="198198"
                                  value={ReactSystemFunctions.translate(this.ml, 198198, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="629393"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="8272"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_8272_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Banka Bilgileri"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      fontSize: "18pt",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="463243"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="761439"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="57825"
                                  kuikaRef={this.musterikart_57825_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListCariBankaBilgileri}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    57825,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="719395"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="490116"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="758810"
                                      title={ReactSystemFunctions.translate(this.ml, 758810, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="386721"
                                        value="[datafield:hesapAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="14082"
                                      title={ReactSystemFunctions.translate(this.ml, 14082, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="354203"
                                        value="[datafield:bankaAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="654840"
                                      title={ReactSystemFunctions.translate(this.ml, 654840, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="753968"
                                        value="[datafield:subeKodu]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="789737"
                                      title={ReactSystemFunctions.translate(this.ml, 789737, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="133851"
                                        value="[datafield:subeAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="271614"
                                      title={ReactSystemFunctions.translate(this.ml, 271614, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="920087" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="220676"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_220676_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="691163"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_691163_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="736511"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="449887"
                                xs={16}
                                sm={16}
                                md={16}
                                lg={16}
                                xl={16}
                                xxl={16}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="446596"
                                  value={ReactSystemFunctions.translate(this.ml, 446596, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="454309"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="715146"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_715146_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Adres Bilgileri"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="840662"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="178243"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="700038"
                                  kuikaRef={this.musterikart_700038_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListCariAdresBilgileri}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    700038,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="431710"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="184564"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="908227"
                                      title={ReactSystemFunctions.translate(this.ml, 908227, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="794627"
                                        value="[datafield:adresTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="197872"
                                      title={ReactSystemFunctions.translate(this.ml, 197872, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="198856"
                                        value="[datafield:adres]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="163638"
                                      title={ReactSystemFunctions.translate(this.ml, 163638, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="46042" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="28061"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_28061_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="357511"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_357511_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="547256"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="216818"
                                xs={18}
                                sm={18}
                                md={18}
                                lg={18}
                                xl={18}
                                xxl={18}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="506597"
                                  value={ReactSystemFunctions.translate(this.ml, 506597, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_269654_value">
                                  <TextInput
                                    id="269654"
                                    kuikaRef={this.musterikart_269654_value_kuikaTextInputRef}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      269654,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    bordered={true}
                                    disabled={false}
                                    type="text"
                                    iconColor="rgba(0, 0, 0, 1)"
                                    formatter="None"
                                    isCharOnly={false}
                                    autoComplete={true}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        display: "block",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></TextInput>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="52216"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 0,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="807548"
                                  value={ReactSystemFunctions.translate(this.ml, 807548, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="musterikart_669306_value">
                                  <KSelectBox
                                    id="669306"
                                    kuikaRef={this.musterikart_669306_value_kuikaSelectBoxRef}
                                    options={this.state.dataSource_669306}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      669306,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="key"
                                    datatextfield="text"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        height: "32px",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="165412"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="794680"
                                xs={20}
                                sm={20}
                                md={20}
                                lg={20}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    alignItems: "center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="764802"
                                  value={ReactSystemFunctions.translate(this.ml, 764802, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="784610"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="238029"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.MusteriKartComponent_238029_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="add_circle"
                                  hoverText="Lisans Sözleşmeleri, Fatura ve İzinler"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingLeft: 16,
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="754127"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="580304"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="98439"
                                  kuikaRef={this.musterikart_98439_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListCariSozlesmeBilgileri}
                                  size="middle"
                                  bordered={true}
                                  showHeader={true}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    98439,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={true}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="445627"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                        color: "rgba(172, 38, 42, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="43393"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="901829"
                                      title={ReactSystemFunctions.translate(this.ml, 901829, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="843021"
                                        value="[datafield:alan]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="361784"
                                      title={ReactSystemFunctions.translate(this.ml, 361784, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="561783"
                                        value="[datafield:sozlesmeTipi]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="993420"
                                      title={ReactSystemFunctions.translate(this.ml, 993420, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="284906"
                                        value="[datafield:sozlesmeKonu]"
                                        formatter=""
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="925870"
                                      title={ReactSystemFunctions.translate(this.ml, 925870, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="938284"
                                        value="[datafield:basTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="697593"
                                      title={ReactSystemFunctions.translate(this.ml, 697593, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="97617"
                                        value="[datafield:bitTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 6,
                                            paddingBottom: 6,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="387286"
                                      title={ReactSystemFunctions.translate(this.ml, 387286, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-center",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="645853"
                                        onClick={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MusteriKartComponent_645853_onClick();
                                        }}
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MusteriKartComponent_645853_onChange();
                                        }}
                                        showCursorPointer
                                        visibility={this.state.isComp645853Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.dosya, "")
                                        }
                                        iconName="sim_card_download"
                                        hoverText="[datafield:dosyaAd]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Icon>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="161035"
                                      title={ReactSystemFunctions.translate(this.ml, 161035, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(255, 255, 255, 1)",
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="575038" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="482140"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_482140_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="227884"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.MusteriKartComponent_227884_onClick();
                                          }}
                                          showCursorPointer
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingBottom: 8,
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="820946"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible820946: false }, () => {
                      this.callCallbackFunction(820946);
                    });
                  }}
                  visible={this.state.NavVisible820946}
                  push={true}
                  children={
                    this.state.NavVisible820946 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible820946: false }, () => {
                            this.callCallbackFunction(820946);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="199855"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible199855: false }, () => {
                      this.callCallbackFunction(199855);
                    });
                  }}
                  visible={this.state.NavVisible199855}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible199855 && (
                      <CariIlgiliKisiler_Screen
                        onClose={() => {
                          this.setState({ NavVisible199855: false }, () => {
                            this.callCallbackFunction(199855);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="422009"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible422009: false }, () => {
                      this.callCallbackFunction(422009);
                    });
                  }}
                  visible={this.state.NavVisible422009}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible422009 && (
                      <CariAdresler_Screen
                        onClose={() => {
                          this.setState({ NavVisible422009: false }, () => {
                            this.callCallbackFunction(422009);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="329094"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible329094: false }, () => {
                      this.callCallbackFunction(329094);
                    });
                  }}
                  visible={this.state.NavVisible329094}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible329094 && (
                      <CariIBankaBilgileri_Screen
                        onClose={() => {
                          this.setState({ NavVisible329094: false }, () => {
                            this.callCallbackFunction(329094);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="397141"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible397141: false }, () => {
                      this.callCallbackFunction(397141);
                    });
                  }}
                  visible={this.state.NavVisible397141}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible397141 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible397141: false }, () => {
                            this.callCallbackFunction(397141);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="854058"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible854058: false }, () => {
                      this.callCallbackFunction(854058);
                    });
                  }}
                  visible={this.state.NavVisible854058}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible854058 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible854058: false }, () => {
                            this.callCallbackFunction(854058);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="402622"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible402622: false }, () => {
                      this.callCallbackFunction(402622);
                    });
                  }}
                  visible={this.state.NavVisible402622}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible402622 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible402622: false }, () => {
                            this.callCallbackFunction(402622);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="211229"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible211229: false }, () => {
                      this.callCallbackFunction(211229);
                    });
                  }}
                  visible={this.state.NavVisible211229}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible211229 && (
                      <CariIlgiliKisiler_Screen
                        onClose={() => {
                          this.setState({ NavVisible211229: false }, () => {
                            this.callCallbackFunction(211229);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="249650"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible249650: false }, () => {
                      this.callCallbackFunction(249650);
                    });
                  }}
                  visible={this.state.NavVisible249650}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible249650 && (
                      <CariIBankaBilgileri_Screen
                        onClose={() => {
                          this.setState({ NavVisible249650: false }, () => {
                            this.callCallbackFunction(249650);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="201256"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible201256: false }, () => {
                      this.callCallbackFunction(201256);
                    });
                  }}
                  visible={this.state.NavVisible201256}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible201256 && (
                      <CariSozlesmeler_Screen
                        onClose={() => {
                          this.setState({ NavVisible201256: false }, () => {
                            this.callCallbackFunction(201256);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="392090"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible392090: false }, () => {
                      this.callCallbackFunction(392090);
                    });
                  }}
                  visible={this.state.NavVisible392090}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible392090 && (
                      <CariAdresler_Screen
                        onClose={() => {
                          this.setState({ NavVisible392090: false }, () => {
                            this.callCallbackFunction(392090);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(MusteriKart_Screen)))))
);
export { tmp as MusteriKart_Screen };
//export default tmp;
//export { tmp as MusteriKart_Screen };
