import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMusteriKart_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMusteriKart_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  PrintReport: string;
  PrintReport_dummy: string;
  PrintPdf: string;
  PrintPdf_dummy: string;
  GoBack: any;
  GoBack_dummy: any;
  getEserSahibiVar1Yok0: any[];
  getEserSahibiVar1Yok0_dummy: any[];
  getAnaKatalogVar1Yok0: any[];
  getAnaKatalogVar1Yok0_dummy: any[];
  getListCariIlgiliKisiler: any[];
  getListCariIlgiliKisiler_dummy: any[];
  getListCariYapilanIslertarihcesi: any[];
  getListCariYapilanIslertarihcesi_dummy: any[];
  getListCariAksiyonlar: any[];
  getListCariAksiyonlar_dummy: any[];
  getListBFormuAksiyon: any[];
  getListBFormuAksiyon_dummy: any[];
  getListCariVarisBilgileri: any[];
  getListCariVarisBilgileri_dummy: any[];
  getListCariSozlesmeBilgileri: any[];
  getListCariSozlesmeBilgileri_dummy: any[];
  getListCariBankaBilgileri: any[];
  getListCariBankaBilgileri_dummy: any[];
  getListCariAdresBilgileri: any[];
  getListCariAdresBilgileri_dummy: any[];
  SelectCariById: any[];
  SelectCariById_dummy: any[];
  getListFiyatListeAd: any[];
  getListFiyatListeAd_dummy: any[];
  sbUlkeList: any[];
  sbUlkeList_dummy: any[];
  sbIlList: any[];
  sbIlList_dummy: any[];
  sbIlceList: any[];
  sbIlceList_dummy: any[];
  sbCariGrupList: any[];
  sbCariGrupList_dummy: any[];
  sbMusteriTuruList: any[];
  sbMusteriTuruList_dummy: any[];
  getListMusteriTuruByCariID: any[];
  getListMusteriTuruByCariID_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  getEserSahibiIdByCariId: any[];
  getEserSahibiIdByCariId_dummy: any[];
  getAnaKatalogIdByCariId: any[];
  getAnaKatalogIdByCariId_dummy: any[];
  CariMusteriTuruSave: number;
  CariMusteriTuruSave_dummy: number;
  CariMusteriTuruDelete: number;
  CariMusteriTuruDelete_dummy: number;
  OpenInBrowser: any;
  OpenInBrowser_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  setCariKartSave: number;
  setCariKartSave_dummy: number;
  TriggerEvent: string;
  TriggerEvent_dummy: string;
  setBasvuruFormuAksiyonlarDelete: number;
  setBasvuruFormuAksiyonlarDelete_dummy: number;
  setKartCariIlgiliKisilerDelete: number;
  setKartCariIlgiliKisilerDelete_dummy: number;
  setKartCariBankaBilgileriDelete: number;
  setKartCariBankaBilgileriDelete_dummy: number;
  setKartCariAdresBilgileriDelete: number;
  setKartCariAdresBilgileriDelete_dummy: number;
  DownloadFile: any;
  DownloadFile_dummy: any;
  setKartCariSozlesmeBilgileriDelete: number;
  setKartCariSozlesmeBilgileriDelete_dummy: number;
  isComp435813Visible: "visible" | "hidden";
  isComp949495Visible: "visible" | "hidden";
  isComp973775Visible: "visible" | "hidden";
  isComp829244Visible: "visible" | "hidden";
  isComp360475Visible: "visible" | "hidden";
  isComp684316Visible: "visible" | "hidden";
  isComp645853Visible: "visible" | "hidden";
}

export class MusteriKart_ScreenBase extends React.PureComponent<IMusteriKart_ScreenProps, any> {
  musterikart_387331_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_989333_value_kuikaTableRef: React.RefObject<any>;
  musterikart_626253_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_692424_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_392410_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_617458_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_130742_value_kuikaTableRef: React.RefObject<any>;
  musterikart_586219_value_kuikaTableRef: React.RefObject<any>;
  musterikart_57825_value_kuikaTableRef: React.RefObject<any>;
  musterikart_700038_value_kuikaTableRef: React.RefObject<any>;
  musterikart_669306_value_kuikaSelectBoxRef: React.RefObject<any>;
  musterikart_98439_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "16c151eb-42cf-0b2b-7e4a-e8b04fc43d88",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "fbdbce9f-3fdb-30c5-c4fe-f7e443068251", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    { Id: "8468043e-f025-01e1-93b8-c71e3215778a", Name: "de_DE", ShortName: "German", LanguagePhrases: [] },
    {
      Id: "41bc1c5c-09b8-4134-b570-8b84f0ca0de8",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 785916, PropertyName: "value", Value: "Müşteri Kartı" },
        { Id: 70279, PropertyName: "value", Value: "Müşteri Adı" },
        { Id: 238965, PropertyName: "value", Value: "Müşteri Türü" },
        { Id: 89186, PropertyName: "value", Value: "Eser Sahibi Kartına Git" },
        { Id: 22958, PropertyName: "value", Value: "Katalog Kartına Git" },
        { Id: 387331, PropertyName: "placeholder", Value: "Kişi Seçiniz..." },
        { Id: 989333, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 483786, PropertyName: "value", Value: "[datafield:mturutanim]" },
        { Id: 597603, PropertyName: "value", Value: "Adres" },
        { Id: 908497, PropertyName: "value", Value: "Ülke" },
        { Id: 250539, PropertyName: "value", Value: "İl" },
        { Id: 463982, PropertyName: "value", Value: "İlçe" },
        { Id: 484939, PropertyName: "value", Value: "Posta Kodu" },
        { Id: 876194, PropertyName: "value", Value: "Telefon 1" },
        { Id: 380113, PropertyName: "value", Value: "Telefon 2" },
        { Id: 145098, PropertyName: "value", Value: "Telefon 3" },
        { Id: 336009, PropertyName: "value", Value: "EMail" },
        { Id: 550638, PropertyName: "value", Value: "Web Sitesi" },
        { Id: 157781, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 798245, PropertyName: "value", Value: "Vergi Numarası" },
        { Id: 286479, PropertyName: "value", Value: "TC Kimlik Numarası" },
        { Id: 19185, PropertyName: "value", Value: "Pasaport Numarası" },
        { Id: 941444, PropertyName: "value", Value: "Fiyat Listesi" },
        { Id: 916126, PropertyName: "value", Value: "MAMA (Müzik Bankası) Sözleşme Durumu" },
        { Id: 647515, PropertyName: "value", Value: "VAR" },
        { Id: 392021, PropertyName: "value", Value: "YOK" },
        { Id: 60145, PropertyName: "value", Value: "Muhasebe Kodu (120)" },
        { Id: 575338, PropertyName: "value", Value: "Muhasebe Kodu (320)" },
        { Id: 614102, PropertyName: "value", Value: "Muhasebe Kodu (600)" },
        { Id: 360475, PropertyName: "value", Value: "Satış Stopaj (%)" },
        { Id: 754623, PropertyName: "value", Value: "Dağıtım Stopajı (%)" },
        { Id: 861691, PropertyName: "label", Value: "Vazgeç" },
        { Id: 356553, PropertyName: "label", Value: "Kaydet" },
        { Id: 516287, PropertyName: "value", Value: "Önemli Notlar" },
        { Id: 635010, PropertyName: "value", Value: "Güncel Gelişmeler ve Notlar" },
        { Id: 124150, PropertyName: "title", Value: "Tarih" },
        { Id: 588709, PropertyName: "value", Value: "[datafield:aksiyontarihi]" },
        { Id: 790683, PropertyName: "title", Value: "Güncellemeyi Yapan" },
        { Id: 635026, PropertyName: "value", Value: "[datafield:nick]" },
        { Id: 156255, PropertyName: "title", Value: "Güncelleme Türü" },
        { Id: 534677, PropertyName: "value", Value: "[datafield:aksiyontanim]" },
        { Id: 178001, PropertyName: "title", Value: "Güncel Durum Detayı" },
        { Id: 58767, PropertyName: "value", Value: "[datafield:aciklama]" },
        { Id: 450613, PropertyName: "value", Value: "Ek İsim ve İletişim Bilgileri" },
        { Id: 586219, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 839802, PropertyName: "title", Value: "Ad Soyad" },
        { Id: 432076, PropertyName: "value", Value: "[datafield:adsoyad]" },
        { Id: 574033, PropertyName: "title", Value: "Görevi" },
        { Id: 56614, PropertyName: "value", Value: "[datafield:gorevi]" },
        { Id: 330558, PropertyName: "title", Value: "Telefon" },
        { Id: 164471, PropertyName: "value", Value: "[datafield:telefon]" },
        { Id: 754739, PropertyName: "title", Value: "Mail" },
        { Id: 128319, PropertyName: "value", Value: "[datafield:email]" },
        { Id: 198198, PropertyName: "value", Value: "Banka Bilgileri" },
        { Id: 57825, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 758810, PropertyName: "title", Value: "Hesap Adı" },
        { Id: 386721, PropertyName: "value", Value: "[datafield:hesapad]" },
        { Id: 14082, PropertyName: "title", Value: "Banka Ad" },
        { Id: 354203, PropertyName: "value", Value: "[datafield:bankaad]" },
        { Id: 654840, PropertyName: "title", Value: "Şube Kodu" },
        { Id: 753968, PropertyName: "value", Value: "[datafield:subekodu]" },
        { Id: 789737, PropertyName: "title", Value: "Şube Ad" },
        { Id: 133851, PropertyName: "value", Value: "[datafield:subead]" },
        { Id: 446596, PropertyName: "value", Value: "Adres Bilgileri" },
        { Id: 700038, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 908227, PropertyName: "title", Value: "Adres Tanımı" },
        { Id: 794627, PropertyName: "value", Value: "[datafield:adrestanim]" },
        { Id: 197872, PropertyName: "title", Value: "Adres" },
        { Id: 198856, PropertyName: "value", Value: "[datafield:adres]" },
        { Id: 506597, PropertyName: "value", Value: "EMail Bilgileri" },
        { Id: 807548, PropertyName: "value", Value: "Dil" },
        { Id: 764802, PropertyName: "value", Value: "Lisans Sözleşmeleri, Fatura ve İzinler" },
        { Id: 98439, PropertyName: "nodatafoundmessage", Value: "Veri Bulunamadı" },
        { Id: 901829, PropertyName: "title", Value: "Alan" },
        { Id: 843021, PropertyName: "value", Value: "[datafield:alan]" },
        { Id: 361784, PropertyName: "title", Value: "Tür" },
        { Id: 561783, PropertyName: "value", Value: "[datafield:sozlesmetipi]" },
        { Id: 993420, PropertyName: "title", Value: "Konu" },
        { Id: 284906, PropertyName: "value", Value: "[datafield:sozlesmekonu]" },
        { Id: 925870, PropertyName: "title", Value: "Baş.Tarihi" },
        { Id: 938284, PropertyName: "value", Value: "[datafield:bastarihi]" },
        { Id: 697593, PropertyName: "title", Value: "Bit.Tarihi" },
        { Id: 97617, PropertyName: "value", Value: "[datafield:bittarihi]" },
        { Id: 387286, PropertyName: "title", Value: "PDF" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.musterikart_802808_value_kuikaTextInputRef = React.createRef();
    this.musterikart_387331_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_989333_value_kuikaTableRef = React.createRef();
    this.musterikart_941181_value_kuikaTextInputRef = React.createRef();
    this.musterikart_626253_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_692424_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_392410_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_567110_value_kuikaTextInputRef = React.createRef();
    this.musterikart_626884_value_kuikaTextInputRef = React.createRef();
    this.musterikart_733652_value_kuikaTextInputRef = React.createRef();
    this.musterikart_125943_value_kuikaTextInputRef = React.createRef();
    this.musterikart_668754_value_kuikaTextInputRef = React.createRef();
    this.musterikart_632170_value_kuikaTextInputRef = React.createRef();
    this.musterikart_266751_value_kuikaTextInputRef = React.createRef();
    this.musterikart_439202_value_kuikaTextInputRef = React.createRef();
    this.musterikart_565265_value_kuikaTextInputRef = React.createRef();
    this.musterikart_64361_value_kuikaTextInputRef = React.createRef();
    this.musterikart_617458_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_982273_value_kuikaTextInputRef = React.createRef();
    this.musterikart_83025_value_kuikaTextInputRef = React.createRef();
    this.musterikart_151962_value_kuikaTextInputRef = React.createRef();
    this.musterikart_130742_value_kuikaTableRef = React.createRef();
    this.musterikart_586219_value_kuikaTableRef = React.createRef();
    this.musterikart_57825_value_kuikaTableRef = React.createRef();
    this.musterikart_700038_value_kuikaTableRef = React.createRef();
    this.musterikart_269654_value_kuikaTextInputRef = React.createRef();
    this.musterikart_669306_value_kuikaSelectBoxRef = React.createRef();
    this.musterikart_98439_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      PrintReport: "",
      PrintPdf: "",
      GoBack: "",
      getEserSahibiVar1Yok0: [],
      getAnaKatalogVar1Yok0: [],
      getListCariIlgiliKisiler: [],
      getListCariYapilanIslertarihcesi: [],
      getListCariAksiyonlar: [],
      getListBFormuAksiyon: [],
      getListCariVarisBilgileri: [],
      getListCariSozlesmeBilgileri: [],
      getListCariBankaBilgileri: [],
      getListCariAdresBilgileri: [],
      SelectCariById: [],
      getListFiyatListeAd: [],
      sbUlkeList: [],
      sbIlList: [],
      sbIlceList: [],
      sbCariGrupList: [],
      sbMusteriTuruList: [],
      getListMusteriTuruByCariID: [],
      ChangeVisibilityOf: "",
      getEserSahibiIdByCariId: [],
      getAnaKatalogIdByCariId: [],
      CariMusteriTuruSave: "",
      CariMusteriTuruDelete: "",
      OpenInBrowser: "",
      SetValueOf: "",
      setCariKartSave: 0,
      TriggerEvent: "",
      setBasvuruFormuAksiyonlarDelete: "",
      setKartCariIlgiliKisilerDelete: "",
      setKartCariBankaBilgileriDelete: "",
      setKartCariAdresBilgileriDelete: "",
      DownloadFile: "",
      setKartCariSozlesmeBilgileriDelete: "",
      dataSource_669306: [
        { key: "tr", text: "TR" },
        { key: "en", text: "EN" }
      ],
      isComp435813Visible: "hidden",
      isComp949495Visible: "hidden",
      isComp973775Visible: "hidden",
      isComp829244Visible: "hidden",
      isComp360475Visible: "hidden",
      isComp684316Visible: "hidden",
      isComp645853Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("musterikart", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.MusteriKartPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("musterikart", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("musterikart", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MusteriKartPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      musterikart_802808_value: this.state.SelectCariById?.at?.(0)?.cariAd ?? undefined,
      musterikart_941181_value: this.state.SelectCariById?.at?.(0)?.cariAdres ?? undefined,
      musterikart_626253_value: this.state.SelectCariById?.at?.(0)?.ulkeKodu ?? undefined,
      musterikart_692424_value: this.state.SelectCariById?.at?.(0)?.ilKodu ?? undefined,
      musterikart_392410_value: this.state.SelectCariById?.at?.(0)?.ilceKodu ?? undefined,
      musterikart_567110_value: this.state.SelectCariById?.at?.(0)?.postaKodu ?? undefined,
      musterikart_626884_value: this.state.SelectCariById?.at?.(0)?.tel1 ?? undefined,
      musterikart_733652_value: this.state.SelectCariById?.at?.(0)?.tel2 ?? undefined,
      musterikart_125943_value: this.state.SelectCariById?.at?.(0)?.tel3 ?? undefined,
      musterikart_668754_value: this.state.SelectCariById?.at?.(0)?.cariMail ?? undefined,
      musterikart_632170_value: this.state.SelectCariById?.at?.(0)?.web ?? undefined,
      musterikart_266751_value: this.state.SelectCariById?.at?.(0)?.cariVD ?? undefined,
      musterikart_439202_value: this.state.SelectCariById?.at?.(0)?.cariVergiNo ?? undefined,
      musterikart_565265_value: this.state.SelectCariById?.at?.(0)?.cariTCNo ?? undefined,
      musterikart_64361_value: this.state.SelectCariById?.at?.(0)?.cariPasaportNo ?? undefined,
      musterikart_193018_value: this.state.SelectCariById?.at?.(0)?.mamaSozlesmesiVarmi ?? undefined,
      musterikart_728658_value: this.state.SelectCariById?.at?.(0)?.mamaSozlesmesiYok ?? undefined,
      musterikart_982273_value: this.state.SelectCariById?.at?.(0)?.cariMhKod120 ?? undefined,
      musterikart_83025_value: this.state.SelectCariById?.at?.(0)?.cariMhKod320 ?? undefined,
      musterikart_151962_value: this.state.SelectCariById?.at?.(0)?.cariMhKod600 ?? undefined,
      musterikart_684316_value: undefined,
      musterikart_601328_value: this.state.SelectCariById?.at?.(0)?.dagitimStopaj ?? undefined,
      musterikart_846368_value: this.state.SelectCariById?.at?.(0)?.cariOnemliNot ?? undefined,
      musterikart_269654_value: this.state.SelectCariById?.at?.(0)?.eMail ?? undefined,
      musterikart_669306_value: this.state.SelectCariById?.at?.(0)?.mailDil ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  MusteriKartPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_4: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      BasvuruFormuID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_7: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_8: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_9: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      CariID_10: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      UlkeMhKodu_13: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectCariById?.length > 0
            ? stateVars.SelectCariById[0].ulkeKodu
            : this.state.SelectCariById?.length > 0
            ? this.state.SelectCariById[0].ulkeKodu
            : null
        ),
        "string"
      ),
      IlMhKodu_14: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.SelectCariById?.length > 0
            ? stateVars.SelectCariById[0].ilKodu
            : this.state.SelectCariById?.length > 0
            ? this.state.SelectCariById[0].ilKodu
            : null
        ),
        "string"
      ),
      CariID_17: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getEserSahibiVar1Yok0 = result?.data.getEserSahibiVar1Yok0;
    stateVars.isComp949495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserSahibiVar1Yok0?.length > 0
            ? stateVars.getEserSahibiVar1Yok0[0].var1Yok0
            : this.state.getEserSahibiVar1Yok0?.length > 0
            ? this.state.getEserSahibiVar1Yok0[0].var1Yok0
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getAnaKatalogVar1Yok0 = result?.data.getAnaKatalogVar1Yok0;
    stateVars.isComp973775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getAnaKatalogVar1Yok0?.length > 0
            ? stateVars.getAnaKatalogVar1Yok0[0].var1Yok0
            : this.state.getAnaKatalogVar1Yok0?.length > 0
            ? this.state.getAnaKatalogVar1Yok0[0].var1Yok0
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

    stateVars.getListCariYapilanIslertarihcesi = result?.data.getListCariYapilanIslertarihcesi;
    stateVars.getListCariAksiyonlar = result?.data.getListCariAksiyonlar;
    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    stateVars.getListCariVarisBilgileri = result?.data.getListCariVarisBilgileri;
    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    stateVars.getListCariBankaBilgileri = result?.data.getListCariBankaBilgileri;

    stateVars.getListCariAdresBilgileri = result?.data.getListCariAdresBilgileri;

    stateVars.SelectCariById = result?.data.selectCariById;
    formVars.musterikart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariAd
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariAd
        : null
    );
    formVars.musterikart_941181_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariAdres
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariAdres
        : null
    );
    formVars.musterikart_626253_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ulkeKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ulkeKodu
        : null;
    formVars.musterikart_626253_options = stateVars.sbUlkeList;
    formVars.musterikart_692424_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilKodu
        : null;
    formVars.musterikart_692424_options = stateVars.sbIlList;
    formVars.musterikart_392410_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilceKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilceKodu
        : null;
    formVars.musterikart_392410_options = stateVars.sbIlceList;
    formVars.musterikart_567110_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].postaKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].postaKodu
        : null
    );
    formVars.musterikart_626884_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel1
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel1
        : null
    );
    formVars.musterikart_733652_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel2
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel2
        : null
    );
    formVars.musterikart_125943_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel3
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel3
        : null
    );
    formVars.musterikart_668754_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMail
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMail
        : null
    );
    formVars.musterikart_632170_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].web
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].web
        : null
    );
    formVars.musterikart_266751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariVD
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariVD
        : null
    );
    formVars.musterikart_439202_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariVergiNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariVergiNo
        : null
    );
    formVars.musterikart_565265_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariTCNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariTCNo
        : null
    );
    formVars.musterikart_64361_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariPasaportNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariPasaportNo
        : null
    );
    formVars.musterikart_193018_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mamaSozlesmesiVarmi
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mamaSozlesmesiVarmi
        : null
    );
    formVars.musterikart_728658_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mamaSozlesmesiYok
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mamaSozlesmesiYok
        : null
    );
    formVars.musterikart_982273_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod120
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod120
        : null
    );
    formVars.musterikart_83025_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod320
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod320
        : null
    );
    formVars.musterikart_151962_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod600
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod600
        : null
    );
    formVars.musterikart_601328_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].dagitimStopaj
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].dagitimStopaj
        : null
    );
    formVars.musterikart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariOnemliNot
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariOnemliNot
        : null
    );
    formVars.musterikart_269654_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].eMail
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].eMail
        : null
    );
    formVars.musterikart_669306_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mailDil
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mailDil
        : null;
    stateVars.getListFiyatListeAd = result?.data.getListFiyatListeAd;

    formVars.musterikart_617458_options = stateVars.getListFiyatListeAd;
    stateVars.sbUlkeList = result?.data.sbUlkeList;

    formVars.musterikart_626253_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ulkeKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ulkeKodu
        : null;
    formVars.musterikart_626253_options = stateVars.sbUlkeList;
    stateVars.sbIlList = result?.data.sbIlList;

    formVars.musterikart_692424_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilKodu
        : null;
    formVars.musterikart_692424_options = stateVars.sbIlList;
    stateVars.sbIlceList = result?.data.sbIlceList;

    formVars.musterikart_392410_value =
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilceKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilceKodu
        : null;
    formVars.musterikart_392410_options = stateVars.sbIlceList;
    stateVars.sbCariGrupList = result?.data.sbCariGrupList;
    stateVars.sbMusteriTuruList = result?.data.sbMusteriTuruList;

    formVars.musterikart_387331_options = stateVars.sbMusteriTuruList;
    stateVars.getListMusteriTuruByCariID = result?.data.getListMusteriTuruByCariID;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.musterikart_802808_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariAd
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariAd
        : null
    );

    stateVars.isComp949495Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserSahibiVar1Yok0?.length > 0
            ? stateVars.getEserSahibiVar1Yok0[0].var1Yok0
            : this.state.getEserSahibiVar1Yok0?.length > 0
            ? this.state.getEserSahibiVar1Yok0[0].var1Yok0
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp973775Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.getAnaKatalogVar1Yok0?.length > 0
            ? stateVars.getAnaKatalogVar1Yok0[0].var1Yok0
            : this.state.getAnaKatalogVar1Yok0?.length > 0
            ? this.state.getAnaKatalogVar1Yok0[0].var1Yok0
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_387331 = this.state.sbMusteriTuruList;
    stateVars.dataSource_387331 = this.state.sbMusteriTuruList;

    stateVars.dataSource_989333 = this.state.getListMusteriTuruByCariID;
    formVars.musterikart_941181_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariAdres
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariAdres
        : null
    );

    formVars.musterikart_626253_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ulkeKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ulkeKodu
        : null
    );

    stateVars.dataSource_626253 = this.state.sbUlkeList;
    stateVars.dataSource_626253 = this.state.sbUlkeList;
    formVars.musterikart_692424_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilKodu
        : null
    );

    stateVars.dataSource_692424 = this.state.sbIlList;
    stateVars.dataSource_692424 = this.state.sbIlList;
    formVars.musterikart_392410_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].ilceKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].ilceKodu
        : null
    );

    stateVars.dataSource_392410 = this.state.sbIlceList;
    stateVars.dataSource_392410 = this.state.sbIlceList;
    formVars.musterikart_567110_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].postaKodu
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].postaKodu
        : null
    );

    formVars.musterikart_626884_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel1
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel1
        : null
    );

    formVars.musterikart_733652_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel2
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel2
        : null
    );

    formVars.musterikart_125943_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].tel3
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].tel3
        : null
    );

    formVars.musterikart_668754_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMail
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMail
        : null
    );

    formVars.musterikart_632170_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].web
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].web
        : null
    );

    formVars.musterikart_266751_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariVD
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariVD
        : null
    );

    formVars.musterikart_439202_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariVergiNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariVergiNo
        : null
    );

    formVars.musterikart_565265_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariTCNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariTCNo
        : null
    );

    formVars.musterikart_64361_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariPasaportNo
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariPasaportNo
        : null
    );

    stateVars.dataSource_617458 = this.state.getListFiyatListeAd;
    stateVars.dataSource_617458 = this.state.getListFiyatListeAd;
    formVars.musterikart_193018_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mamaSozlesmesiVarmi
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mamaSozlesmesiVarmi
        : null
    );

    formVars.musterikart_728658_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mamaSozlesmesiYok
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mamaSozlesmesiYok
        : null
    );

    formVars.musterikart_982273_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod120
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod120
        : null
    );

    formVars.musterikart_83025_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod320
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod320
        : null
    );

    formVars.musterikart_151962_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariMhKod600
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariMhKod600
        : null
    );

    formVars.musterikart_601328_value = ReactSystemFunctions.value(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].dagitimStopaj
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].dagitimStopaj
        : null
    );

    formVars.musterikart_846368_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].cariOnemliNot
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].cariOnemliNot
        : null
    );

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    stateVars.dataSource_57825 = this.state.getListCariBankaBilgileri;

    stateVars.dataSource_700038 = this.state.getListCariAdresBilgileri;
    formVars.musterikart_269654_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].eMail
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].eMail
        : null
    );

    formVars.musterikart_669306_value = ReactSystemFunctions.toString(
      this,
      stateVars.SelectCariById?.length > 0
        ? stateVars.SelectCariById[0].mailDil
        : this.state.SelectCariById?.length > 0
        ? this.state.SelectCariById[0].mailDil
        : null
    );

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;
    stateVars.isComp645853Visible =
      ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.value(this, "musterikart_98439_value", "dosya"), null) ===
      true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MusteriKartComponent_83067_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "820946",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "left",
      null,
      "",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_661163_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      reportName_0: ReactSystemFunctions.convertToTypeByName("96b4a777-a550-4255-a471-2cc5f9b292bb", "string"),
      prmIId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      prmCId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_661163_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PrintReport = result?.data.printReport;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_661163_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_661163_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.PrintReport)
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_439745_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      reportName_0: ReactSystemFunctions.convertToTypeByName("4ad0e97d-9470-4ad5-b32c-276a14de8d54", "string"),
      prmIId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      prmCId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_439745_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PrintReport = result?.data.printReport;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_439745_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_439745_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.PrintReport)
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_996082_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_782963_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp435813Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("visible", "string")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_949495_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_949495_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getEserSahibiIdByCariId = result?.data.getEserSahibiIdByCariId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_949495_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_949495_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserSahibiIdByCariId?.length > 0
            ? stateVars.getEserSahibiIdByCariId[0].eserSahibiId
            : this.state.getEserSahibiIdByCariId?.length > 0
            ? this.state.getEserSahibiIdByCariId[0].eserSahibiId
            : null
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "EserSahibiKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_89186_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_89186_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getEserSahibiIdByCariId = result?.data.getEserSahibiIdByCariId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_89186_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_89186_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "EserSahibiKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getEserSahibiIdByCariId?.length > 0
            ? stateVars.getEserSahibiIdByCariId[0].eserSahibiId
            : this.state.getEserSahibiIdByCariId?.length > 0
            ? this.state.getEserSahibiIdByCariId[0].eserSahibiId
            : null
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "EserSahibiKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_973775_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_973775_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAnaKatalogIdByCariId = result?.data.getAnaKatalogIdByCariId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_973775_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_973775_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AnaKatalogKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getAnaKatalogIdByCariId?.length > 0
            ? stateVars.getAnaKatalogIdByCariId[0].anaKatalogId
            : this.state.getAnaKatalogIdByCariId?.length > 0
            ? this.state.getAnaKatalogIdByCariId[0].anaKatalogId
            : null
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "AnaKatalogKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_22958_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_22958_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getAnaKatalogIdByCariId = result?.data.getAnaKatalogIdByCariId;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_22958_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_22958_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AnaKatalogKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getAnaKatalogIdByCariId?.length > 0
            ? stateVars.getAnaKatalogIdByCariId[0].anaKatalogId
            : this.state.getAnaKatalogIdByCariId?.length > 0
            ? this.state.getAnaKatalogIdByCariId[0].anaKatalogId
            : null
        ),
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "AnaKatalogKart",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_121663_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      ),
      MusteriTuruID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterikart_387331_value", "value", "sbMusteriTuruList", "id", "id")
        ),
        "Guid"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_121663_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CariMusteriTuruSave = result?.data.cariMusteriTuruSave;
    stateVars.getListMusteriTuruByCariID = result?.data.getListMusteriTuruByCariID;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_121663_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_121663_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_989333 = this.state.getListMusteriTuruByCariID;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_69679_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      ReactSystemFunctions.convertToTypeByName("isComp435813Visible", "string"),
      ReactSystemFunctions.convertToTypeByName("hidden", "string")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_989982_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_989333_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "d97a4ef4_6cec_9df0_8452_44d3b04f92c2_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_989982_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CariMusteriTuruDelete = result?.data.cariMusteriTuruDelete;
      stateVars.getListMusteriTuruByCariID = result?.data.getListMusteriTuruByCariID;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriKartComponent_989982_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_989982_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_989333 = this.state.getListMusteriTuruByCariID;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_626253_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UlkeMhKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_626253_value", "value", "sbUlkeList", "mhKodu", "mhKodu")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_626253_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbIlList = result?.data.sbIlList;

    formVars.musterikart_692424_options = stateVars.sbIlList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_626253_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_626253_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_692424 = this.state.sbIlList;
    stateVars.dataSource_692424 = this.state.sbIlList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_692424_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      IlMhKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_692424_value", "value", "sbIlList", "mhKodu", "mhKodu")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_692424_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.sbIlceList = result?.data.sbIlceList;

    formVars.musterikart_392410_options = stateVars.sbIlceList;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_692424_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_692424_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_392410 = this.state.sbIlceList;
    stateVars.dataSource_392410 = this.state.sbIlceList;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_883219_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.OpenInBrowser = await ReactSystemFunctions.openInBrowser(
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "musterikart_632170_value", "value", "", "", "")
      ),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_193018_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("musterikart_728658_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_728658_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("musterikart_193018_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_861691_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_356553_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_802808_value", "value", "", "", "")
        ),
        null
      ),
      message: "!",
      formName: "musterikart_802808_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_669306_value", "value", "", "key", "")
        ),
        null
      ),
      message: "*",
      formName: "musterikart_669306_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_802808_value", "value", "", "", "")
        ),
        "string"
      ),
      CariAdres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_941181_value", "value", "", "", "")
        ),
        "string"
      ),
      CariMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_668754_value", "value", "", "", "")
        ),
        "string"
      ),
      Web_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_632170_value", "value", "", "", "")
        ),
        "string"
      ),
      CariMhKod120_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_982273_value", "value", "", "", "")
        ),
        "string"
      ),
      CariMhKod320_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_83025_value", "value", "", "", "")
        ),
        "string"
      ),
      CariMhKod600_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_151962_value", "value", "", "", "")
        ),
        "string"
      ),
      CariOnemliNot_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_846368_value", "value", "", "", "")
        ),
        "string"
      ),
      CariPasaportNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_64361_value", "value", "", "", "")
        ),
        "string"
      ),
      CariTCNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_565265_value", "value", "", "", "")
        ),
        "string"
      ),
      Tel1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_626884_value", "value", "", "", "")
        ),
        "string"
      ),
      Tel2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_733652_value", "value", "", "", "")
        ),
        "string"
      ),
      Tel3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_125943_value", "value", "", "", "")
        ),
        "string"
      ),
      CariVD_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_266751_value", "value", "", "", "")
        ),
        "string"
      ),
      CariVergiNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_439202_value", "value", "", "", "")
        ),
        "string"
      ),
      FiyatListeAdID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterikart_617458_value", "value", "getListFiyatListeAd", "id", "id")
        ),
        "Guid"
      ),
      UlkeKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_626253_value", "value", "sbUlkeList", "mhKodu", "mhKodu")
        ),
        "string"
      ),
      IlKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_692424_value", "value", "sbIlList", "mhKodu", "mhKodu")
        ),
        "string"
      ),
      IlceKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_392410_value", "value", "sbIlceList", "mhKodu", "mhKodu")
        ),
        "string"
      ),
      MAMASozlesmesiVarmi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterikart_193018_value", "value", "", "", "")
        ),
        "boolean"
      ),
      MAMASozlesmesiYok_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterikart_728658_value", "value", "", "", "")
        ),
        "boolean"
      ),
      PostaKodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_567110_value", "value", "", "", "")
        ),
        "string"
      ),
      DagitimStopaj_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "musterikart_601328_value", "value", "", "", "")
        ),
        "number"
      ),
      MailDil_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_669306_value", "value", "", "key", "")
        ),
        "string"
      ),
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "musterikart_269654_value", "value", "", "", "")
        ),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_356553_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setCariKartSave = result?.data.setCariKartSave;
    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MusteriKartComponent_429025_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MusteriKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "NewDisplay",
      ReactSystemFunctions.convertToTypeByName("N", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "854058",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_232864_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_130742_value", "id"),
        "Guid"
      ),
      BasvuruFormuID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_232864_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.setBasvuruFormuAksiyonlarDelete = result?.data.setBasvuruFormuAksiyonlarDelete;
    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_232864_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_232864_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_8393_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_130742_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "NewDisplay",
      ReactSystemFunctions.convertToTypeByName("E", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "AksiyonKart",
      "BasvuruFormuID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MusteriKartComponent_8393_onClick1_,
      "MusteriKart",
      "AksiyonKart",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "402622",
      ReactSystemFunctions.convertToTypeByName("", "string"),
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MusteriKartComponent_8393_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BasvuruFormuID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_8393_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListBFormuAksiyon = result?.data.getListBFormuAksiyon;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_8393_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_8393_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_130742 = this.state.getListBFormuAksiyon;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_758058_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MusteriKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "CariIlgiliKisiler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "211229",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_717093_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      reportName_0: ReactSystemFunctions.convertToTypeByName("96b4a777-a550-4255-a471-2cc5f9b292bb", "string"),
      prmIId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_586219_value", "id"),
        "Guid"
      ),
      prmCId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_717093_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PrintReport = result?.data.printReport;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_717093_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_717093_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.PrintReport)
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_47199_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      reportName_0: ReactSystemFunctions.convertToTypeByName("4ad0e97d-9470-4ad5-b32c-276a14de8d54", "string"),
      prmIId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_586219_value", "id"),
        "Guid"
      ),
      prmCId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_47199_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.PrintReport = result?.data.printReport;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_47199_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_47199_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.PrintPdf = await ReactSystemFunctions.printPdf(
      ReactSystemFunctions.toString(this, this.state.PrintReport)
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_123813_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_586219_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "5156216c_728b_d3a2_1939_536fa9f0f06c_confirmation",
        this.defaultML,
        "Seçilen Kayır Silinecek; Devm Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_123813_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariIlgiliKisilerDelete = result?.data.setKartCariIlgiliKisilerDelete;
      stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriKartComponent_123813_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_123813_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_574746_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_586219_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariIlgiliKisiler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MusteriKartComponent_574746_onClick1_,
      "MusteriKart",
      "CariIlgiliKisiler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "199855",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MusteriKartComponent_574746_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_574746_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariIlgiliKisiler = result?.data.getListCariIlgiliKisiler;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_574746_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_574746_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_586219 = this.state.getListCariIlgiliKisiler;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_8272_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MusteriKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariIBankaBilgileri",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariIBankaBilgileri",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "CariIBankaBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "249650",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_220676_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_57825_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "a9ef1b06_755d_2db7_4292_83da869c7308_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_220676_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariBankaBilgileriDelete = result?.data.setKartCariBankaBilgileriDelete;
      stateVars.getListCariBankaBilgileri = result?.data.getListCariBankaBilgileri;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriKartComponent_220676_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_220676_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_57825 = this.state.getListCariBankaBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_691163_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariIBankaBilgileri",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_57825_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariIBankaBilgileri",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MusteriKartComponent_691163_onClick1_,
      "MusteriKart",
      "CariIBankaBilgileri",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "329094",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MusteriKartComponent_691163_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_691163_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariBankaBilgileri = result?.data.getListCariBankaBilgileri;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_691163_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_691163_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_57825 = this.state.getListCariBankaBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_715146_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MusteriKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariAdresler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariAdresler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "CariAdresler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "392090",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_28061_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_700038_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "8000e5be_f4e8_2420_b68d_cbdf4721a9b7_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_28061_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariAdresBilgileriDelete = result?.data.setKartCariAdresBilgileriDelete;
      stateVars.getListCariAdresBilgileri = result?.data.getListCariAdresBilgileri;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriKartComponent_28061_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_28061_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_700038 = this.state.getListCariAdresBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_357511_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariAdresler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_700038_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariAdresler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MusteriKartComponent_357511_onClick1_,
      "MusteriKart",
      "CariAdresler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "422009",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MusteriKartComponent_357511_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_357511_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariAdresBilgileri = result?.data.getListCariAdresBilgileri;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_357511_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_357511_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_700038 = this.state.getListCariAdresBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_238029_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    if (await this.MusteriKartComponent_356553_onClick()) return true;

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("cari", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "MusteriKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "201256",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_645853_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.value(this, "musterikart_98439_value", "dosya"),
      ReactSystemFunctions.value(this, "musterikart_98439_value", "dosyaAd")
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_482140_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_98439_value", "id"),
        "Guid"
      ),
      CariID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f0cd172f_d6e0_a12c_4a8d_f1ef1a129b93_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecek; Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_482140_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.setKartCariSozlesmeBilgileriDelete = result?.data.setKartCariSozlesmeBilgileriDelete;
      stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.MusteriKartComponent_482140_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_482140_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_227884_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmGuid",
      ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "musterikart_98439_value", "id"),
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmCariID",
      ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmNerdenGeldi",
      ReactSystemFunctions.convertToTypeByName("cari", "string")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmIsKartTuruID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );
    KuikaAppManager.addToPageInputVariables(
      "CariSozlesmeler",
      "prmMusteriID",
      ReactSystemFunctions.convertToTypeByName(null, "Guid")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MusteriKartComponent_227884_onClick1_,
      "MusteriKart",
      "CariSozlesmeler",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "397141",
      null,
      "right",
      null,
      "480",
      "",
      true
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MusteriKartComponent_227884_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CariID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmGuid ?? this.props.screenInputs.prmguid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MusteriKart/MusteriKartComponent_227884_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getListCariSozlesmeBilgileri = result?.data.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MusteriKartComponent_227884_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MusteriKartComponent_227884_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_98439 = this.state.getListCariSozlesmeBilgileri;

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MusteriKartComponent_645853_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [
      820946, 854058, 402622, 211229, 199855, 249650, 329094, 392090, 422009, 201256, 397141
    ] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MusteriKartPageInit();
    }
    if (diId == 402622) {
      isErrorOccurred = await this.MusteriKartComponent_8393_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 199855) {
      isErrorOccurred = await this.MusteriKartComponent_574746_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 329094) {
      isErrorOccurred = await this.MusteriKartComponent_691163_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 422009) {
      isErrorOccurred = await this.MusteriKartComponent_357511_onClick1_();
      if (isErrorOccurred) return true;
    } else if (diId == 397141) {
      isErrorOccurred = await this.MusteriKartComponent_227884_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
